import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            open: 'open',
            close: 'close',
          }
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }
    
    init() {
      
    }
    
    open(e) {
      const target = e.currentTarget;
      let template = null;

      if(this.getData('template')) {
        template = this.getData('template');
      }

      if(template) {
        let id = null;
        if(target.getAttribute('data-id')) {
          id = target.getAttribute('data-id');
        }

        jQuery.ajax({
          url: this.ajaxurl,
          type: 'POST',
          data: {'action': 'modal_get_template', 'template': template, 'id': id},
          success: function( response ){
            $('.c-modal').find('.c-modal_wrap').html(response.content);
            $('.c-modal').addClass('is-open');
          },
        });
      }else {
        const div = this.$('modal-wrap');
        const html = $(div).html();
        
        $('.c-modal').find('.c-modal_wrap').html(html);
        $('.c-modal').addClass('is-open');
  
        if(this.getData('cookie')) {
          const cookie = this.getData('cookie');
          $('.c-modal').attr('data-modal-cookie', cookie);
        }
      }

      if($('#pojo-a11y-toolbar').hasClass('pojo-a11y-toolbar-open')) {
        this.call('toggleAccessibility', null, 'Header');
      }
    }

    close() {
      $('.c-modal').removeClass('is-open');
    }
}