import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        // if (document.getElementById('e-page_intro_nav') && screen.width <= 700){
        //     document.getElementById('e-page_intro_nav').setAttribute('data-scroll-section', '');
        // }
    }
}
