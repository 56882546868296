import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                before: 'toggleBefore',
                after: 'toggleAfter',
            }
        }
    }

    init() {
        $('.b-organigramme_content').owlCarousel({
            loop:false,
            margin:10,
            nav:true,
            navText: ['<svg class="fleche_btn b-equipe_before" xmlns="http://www.w3.org/2000/svg" width="17.137" height="12.86" viewBox="0 0 17.137 12.86"><g transform="translate(0 0.707)"><path d="M9.094,8.094h-2V1H0V-1H9.094Z" transform="translate(10 0) rotate(45)" fill="#dc7344"/><path d="M16,1H0V-1H16Z" transform="translate(0 5.723)" fill="#dc7344"/></g></svg>', '<svg class="fleche_btn b-equipe_after" xmlns="http://www.w3.org/2000/svg" width="17.137" height="12.86" viewBox="0 0 17.137 12.86"><g transform="translate(0 0.707)"><path d="M9.094,8.094h-2V1H0V-1H9.094Z" transform="translate(10 0) rotate(45)" fill="#dc7344"/><path d="M16,1H0V-1H16Z" transform="translate(0 5.723)" fill="#dc7344"/></g></svg>'],
            responsive:{
                0:{
                    items:1
                },
                500: {
                    items:2
                },
                900:{
                    items:3
                },
                1400:{
                    items:4
                }
            }
        })
    }
}