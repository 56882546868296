import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          focusin: {
            input: 'active',
          },
          focusout: {
            input: 'desactive',
          },
          change: {
            file: 'initfile',
          }
        }
    }

    init() {

    }

    active(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      item.classList.add('is-active');
    }

    desactive(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if(target.value == "") {
        item.classList.remove('is-active');
      }
    }

    initfile(e) {
      const target = e.currentTarget;
      var $input = $( target ),
      $label	 = $input.next( 'label' ),
      labelVal = $label.html();

      var fileName = '';

      if( target.files && target.files.length > 1 )
        fileName = ( target.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', target.files.length );
      else if( target.value )
        fileName = target.value.split( '\\' ).pop();

      if( fileName )
        $label.find( '.title' ).html( fileName );
      else
        $label.html( labelVal );

    }
}
