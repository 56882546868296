import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                close: 'close',
                level0: 'toggleLevel0',
            },
            scroll: {
                scroll: 'toggleScroll'
            }
        }
    }

    init() {
        $('.close-menu-btn').click(function () {
            $('.main-menu-container').removeClass('--active');
        });

        $('[data-menuid]').click(function () {
            var menuid = $(this).data('menuid');

            $('.c-nav_primary_container').addClass('--active');
            $('.level-2-menu-container').removeClass('--active');

            $('.level-1-menu-container').removeClass('--active');
            $('.level-1-menu-container[data-id="' + menuid + '"]').addClass('--active');
            $('.c-nav_primary_container .custom-option').removeClass('selected');
            $('.c-nav_primary_container .custom-option[data-menuid="' + menuid + '"]').addClass('selected');
            $('.c-nav_primary_container .custom-select__trigger span').text($('.custom-option[data-menuid="' + menuid + '"]').text());
        });

        // $('a[href="#"]').click(function () {
        //     return false;
        // });

        $('.level-1-menu-container .link').click(function () {
            var menuid = $(this).data('levelid');
            $('.level-1-menu-container .link').removeClass('--active');
            $(this).addClass('--active');
            $('.level-2-menu-container').removeClass('--active');
            $('.level-2-menu-container[data-id="' + menuid + '"]').addClass('--active');
        });

        $('.custom-select-wrapper').click(function () {
            $(this).find('.custom-select').toggleClass('open');
        });

        $('.toggle-sub-menu').click(function () {
            $(this).parents('.menu-item-wrapper').toggleClass('active');
        });

        $('.custom-option').click(function () {
            if ($(this).data('menuid')) {
                $('.level-1-menu-container .link').removeClass('--active');
                $('.level-2-menu-container').removeClass('--active');
            }
            if ($(this).data('select-offres')) {
                var cat = $(this).data('select-offres');
                refresh_offres(cat);
            }
            var classes = $(this).attr('class');
            if (classes.indexOf('selected') != -1) {
                $(this).addClass('selected');
            } else {
                $(this).parent('.custom-options').find('.custom-option').removeClass('selected');
                $(this).addClass('selected');
                $(this).parents('.custom-select-wrapper').find('.custom-select__trigger span').text($(this).text());
                //$('.custom-select__trigger span').text($(this).text());
            }
        });

        window.addEventListener('click', function (e) {
            const select = document.querySelector('.custom-select');
            if (!select.contains(e.target)) {
                select.classList.remove('open');
            }
        });
    }

    close() {
        const html = document.querySelector('html');
        const burger = document.querySelector('.c-header_nav_burger');
        const text = burger.querySelector('.-hover');
        html.classList.remove('has-navOpen');
        burger.classList.remove('is-active');
        text.textContent = text.dataset.texthover;
    }

    toggleScroll(){
        const cache = getElementById('navScroll'); 
        if (window.scrollTop() > 100) cache.removeAttribute('data-scroll-section'); 
    }

    toggleLevel0(e){
        $(e.currentTarget).find('.custom-select').toggleClass('open');
    }
}
