import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                toggleFolder: 'toggleFolder',
                toggleFiles: 'toggleFiles',
                toggleView: 'toggleView',
                refreshGed: 'refreshGed',
                deletePost: 'deletePost',
            },
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        
    }

    toggleFolder(e) {
        let el = e.currentTarget;
        let id = $(el).parent('.folder-item').attr('data-id');
        // let classes = $(el).parent('.folder-item').attr('class');
        $(el).parent('.folder-item').toggleClass('active');
        $('.folder-divblock[data-parent="'+id+'"').toggleClass('open');
        // if (classes.indexOf('active') != -1) {
        //     $(el).parent('.folder-item').find('.icon svg').removeClass('fa-folder-open');
        //     $(el).parent('.folder-item').find('.icon svg').addClass('fa-folders');
        // }else {
        //     $(el).parent('.folder-item').find('.icon svg').removeClass('fa-folders');
        //     $(el).parent('.folder-item').find('.icon svg').addClass('fa-folder-open');
        // }
    }

    toggleFiles(e) {
        let el = e.currentTarget;
        let id = $(el).parent('.folder-item').attr('data-id');
        this.refreshGed(id);
    }

    toggleView(e) {
        let el = e.currentTarget;
        let classes = $(el).attr('data-class');
        $('.results-wrapper').find('div').removeClass('active');
        $('.'+classes).addClass('active');
        $('.switch-view .item').removeClass('active');
        $(el).addClass('active');
    }

    deletePost(e) {
        let el = e.currentTarget;
        let post_id = $(el).attr('data-post_id');
        let folder_id = $(el).attr('data-folder_id');
        this.delete_post(post_id, folder_id);
    }

    refreshGed(folder_id) {
        $.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'refresh_ged_content', 'folder_id': folder_id},
            success: function( response ) {
                $('.nav-wrapper .folder-divblock .folder-item').removeClass('selected');
                $('.nav-wrapper .folder-divblock .folder-item[data-id=\'' + folder_id+ '\']').addClass('selected');
                $('.ged-table-divblock').html(response.table);
                $('.ged-grid-divblock').html(response.grid);
            },
        });
    }

    delete_post(post_id, folder_id) {
        let $this = this;
        $.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {
                action: 'delete_post',
                post_id: post_id,
                folder_id: folder_id,
            },
            success: function () {
                $this.refreshGed(folder_id);
            },
        });
    }
}
