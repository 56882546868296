import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            change: {
                change: 'refresh',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        if (this.getData('init')) {
            this.refresh();
        } 
    }

    refresh() {
        let form = $(this.el);
        let t = this;
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                
                if ($('.b-offres_listing').length) {
                    $('.b-offres_listing').html(response.content);
                }
                if ($('.b-offres_counter').length) {
                    $('.b-offres_counter').html(response.counter);
                }

                setTimeout(() => {
                    t.call('update', false, 'Scroll'); // update Locomotive Scroll
                    // t.call('scrollTo', '.b-offres', 'Scroll');
                }, 500);
            },
        });
    }
}
