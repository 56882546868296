import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                nav: 'toggleNav',
                toggleAccessibilite: 'toggleAccessibilite',
                toggleSearch: 'toggleSearch',
                close: 'close',
            }
        }
    }

    init() {

    }

    toggleNav(e) {
        const burger = this.$('nav')[0];
        const text = burger.querySelector('.-hover');
        const html = document.querySelector('html');
        html.classList.remove('has-searchOpen');
        if(html.classList.contains('has-navOpen')) {
            if(!e.currentTarget.getAttribute('data-menuid')){
                html.classList.remove('has-navOpen');
                burger.classList.remove('is-active');
            }
        } else {
            html.classList.add('has-navOpen');
            burger.classList.add('is-active');
        }
    }

    toggleAccessibilite() {
        $('#pojo-a11y-toolbar').toggleClass('pojo-a11y-toolbar-open');
    }

    toggleSearch(e) {
        const burger = this.$('nav')[0];
        // const search = this.$('search')[0];
        const html = document.querySelector('html');
        html.classList.remove('has-navOpen');
        burger.classList.remove('is-active');
        if(html.classList.contains('has-searchOpen')) {
            html.classList.remove('has-searchOpen');
            // search.classList.remove('is-active');
        } else {
            html.classList.remove('has-navOpen');
            html.classList.add('has-searchOpen');
            // search.classList.add('is-active');
        }
    }

    close() {
        const html = document.querySelector('html');
        html.classList.remove('has-navOpen');
        html.classList.remove('has-searchOpen');
    }
}
